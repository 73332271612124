.comment {
    fill: white !important;
    background-color: #1976d2;
    border-radius: 50px;
    overflow: visible;
    padding: 5px;
    height: 1.5em !important;
    width: 1.5em !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;

    &.admin {
        background-color: green;
    }
    &.yours {
        background-color: purple;
    }
}
.viewDocCanvasBox .comment {
    height: 1.75em !important;
    width: 1.75em !important;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.legend {
    .caption {
        font-weight: bold;
    }
}
.MuiDialog-paper {
    .comment {
        box-shadow: none !important;
        cursor: unset !important;
    }
}
div#messageTypesView {
    color: white;
}
label#messageTypesView-label {
    color: white;
    &.Mui-focused {
        color: transparent;
    }
}
canvas {
    cursor: pointer;
}
.caption {
    font-size: 0.75em;
}
canvas {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
} 
.fullscreen canvas {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}
.fullscreen .react-pdf__Document {
    overflow: scroll;
    width: min-content;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
    margin-bottom: 5px;
}
.fullscreen .MuiDialog-container.MuiDialog-scrollPaper {
    background: white;
}
.fullscreen .MuiDialog-paperFullScreen {
    background: rgba(128, 128, 128, 0.44);
}
.viewDocCanvasBox {
    overflow-x: auto;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.viewDocBox {
    position: relative;
    overflow: hidden;
}

.noWrap {
    text-wrap: nowrap;
}
.logoUpload {
    width: 80%;
    object-fit: contain;
}
.adminCommentBox {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}
.viewCardDetail.MuiCardContent-root:empty {
    padding: 0;
}
.bmSib:empty~.bm {
    display: block !important;
}
.viewCardHeader .MuiCardHeader-content {
    .MuiCardHeader-title  {
        font-size: 1.5rem;
        line-height: 1.1;
    }
    .MuiCardHeader-subheader {
        font-size: 1rem;
    }
}